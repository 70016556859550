
.mainHeader {
    height: 2rem;
}

.hero {
    background-color: black;
    color: white;
    height: 70%;
    max-height: 500px;
    padding-top: 25px;
}

.hero h1 {
    margin-top: 0;
    font-size: 3rem;
    width: min-content;
}

.hero .text {
  margin-left: 50%;
}

.flourish {
    height: 50px;
    border-left: 8px solid white;
}